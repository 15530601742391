<template>
  <v-container fluid>
    <h4>
      LISTADO DE LLAMADAS
      <v-btn
        icon
        color="success"
        @click="registrarLlamada()"
        v-if="tipo !== 'ver'"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </h4>
    <v-data-table
      :headers="headers"
      :items="llamadas"
      dense
      :items-per-page="-1"
      loading="true"
    >
      <template v-slot:item="row">
        <tr>
          <td>
            <v-btn
              icon
              color="#A43542 "
              x-small
              :disabled="security < 4"
              @click="borrar(row.item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
          <td>{{ row.item.date }}</td>
          <td>{{ row.item.notes }}</td>
          <td>{{ row.item.vendedor }}</td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogLlamadas" max-width="500px" persistent>
      <registroLllamda />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import funcion from "@/mixins/funciones";
export default {
  props: ["tipo"],
  name: "cardLlamadasComponent",
  mixins: [funcion],
  components: {
    registroLllamda: () =>
      import("@/components/comun/registroLlamadaComponent.vue"),
  },
  computed: {
    ...mapState(["llamadas", "dialogLlamadas", "idQuote"]),
  },
  data() {
    return {
      security: JSON.parse(localStorage.getItem("datos"))["id_secutiry"],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      descripcion: "",
      dialog: false,
      menu: false,
      headers: [
        { value: "action", text: "" },
        { value: "date", text: "FECHA" },
        { value: "notes", text: "DESCRIPCIÓN" },
        { value: "vendedor", text: "VENDEDOR" },
      ],
    };
  },
  methods: {
    ...mapMutations([
      "setLlamadas",
      "datosPrincipales",
      "setIdQuote",
      "setDialogLlamadas",
    ]),

    async obtenerListadoLlamadas() {
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getCalls",
        {
          id_quote: this.$route.params.id,
          id_branch: JSON.parse(localStorage.getItem("branch")),
        }
      );

      this.setLlamadas(valores);
    },
    async borrar(id) {
      let dato = this.llamadas.filter((v) => (v.id = id))[0];

      await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "updateCalls/" + id,
        {
          fecha: dato.fecha,
          id_operador: dato.id_operador,
          comentario: dato.comentario,
          status: 0,
        }
      );
      this.obtenerListadoLlamadas();
    },
    registrarLlamada() {
      this.setIdQuote(this.$route.params.id);
      this.setDialogLlamadas(true);
    },
  },
  mounted() {},
  watch: {
    dialogLlamadas() {
      if (this.dialogLlamadas == false) {
        this.obtenerListadoLlamadas();
      }
    },
  },
};
</script>

<style></style>
